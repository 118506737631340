import { useRef, useState } from "react";
import useAuth from "src/hooks/useAuth";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { experimentalStyled } from "@mui/material/styles";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
// import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import HelpOutlineTwoToneIcon from "@mui/icons-material/HelpOutlineTwoTone";
import { limitStr } from "src/utils/helper";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";

const UserBoxButton = experimentalStyled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-top:${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
        line-height:13px;
`
);

const MenuUserBox = experimentalStyled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxCompany = experimentalStyled(Box)(
  ({ theme }) => `
        color: ${theme.colors.primary.main};
        font-weight: bold;
        font-size:13px;
        text-transform: uppercase;
`
);

const UserBoxText = experimentalStyled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-right: ${theme.spacing(1)};
`
);

const UserBoxLabel = experimentalStyled(Typography)(
  ({ theme }) => `
        font-weight: 500;
        color: ${theme.palette.secondary.main};
        font-size:11px;
        display: block;

`
);

function HeaderUserbox() {
  const { t } = useTranslation();

  const history = useHistory();

  const { user, logout, org_name } = useAuth();
  const ref = useRef(null);
  const refHelp = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [isOpenHelp, setOpenHelp] = useState(false);
  const { partner_level } = user;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      history.push("/");
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
      }}
    >
      <UserBoxButton
        color="secondary"
        ref={refHelp}
        onClick={() => setOpenHelp(true)}
      >
        <HelpOutlineTwoToneIcon sx={{ marginRight: 1 }} fontSize="small" />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel style={{ fontSize: "13px" }} variant="body2">
              Help
            </UserBoxLabel>
          </UserBoxText>
        </Hidden>
        {/* <ExpandMoreTwoToneIcon /> */}
      </UserBoxButton>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxCompany variant="body2">{`${user.first_name} ${user.last_name}`}</UserBoxCompany>
            <UserBoxLabel>{limitStr(org_name, 20, "...")}</UserBoxLabel>
          </UserBoxText>
          {/* <ExpandMoreTwoToneIcon sx={{ ml: 1 }} /> */}
        </Hidden>
        <AccountCircleIcon />
      </UserBoxButton>

      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <UserBoxText>
            <UserBoxCompany variant="body1">
              {limitStr(org_name, 20, "...")}
            </UserBoxCompany>
            <UserBoxLabel variant="body2">{`${user.first_name} ${user.last_name}`}</UserBoxLabel>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem button to="/preview/dashboards/profile" component={NavLink}>
            <PersonOutlineTwoToneIcon fontSize="small" />
            <ListItemText primary={t("Profile")} />
          </ListItem>
          {!["L2", "L3"].includes(partner_level) && (
            <ListItem
              button
              to="/preview/dashboards/setting"
              component={NavLink}
            >
              <SettingsTwoToneIcon fontSize="small" />
              <ListItemText primary={t("Settings")} />
            </ListItem>
          )}
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button
            color="primary"
            onClick={handleLogout}
            fullWidth
            sx={{ paddingRight: "75px" }}
          >
            <LockOpenTwoToneIcon sx={{ mr: 1 }} fontSize="small" />
            {t("Sign out")}
          </Button>
        </Box>
      </Popover>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={refHelp.current}
        onClose={() => setOpenHelp(false)}
        open={isOpenHelp}
      >
        <List sx={{ p: 1 }} component="nav">
          {partner_level !== "L3" && (
            <ListItem
              button
              onClick={() => {
                window.open("https://docs.finmo.net/", "_blank");
                setOpenHelp(false);
              }}
            >
              <ListItemText primary={t("API Reference")} />
            </ListItem>
          )}
          <ListItem
            button
            onClick={() => {
              window.FreshworksWidget("open");
              setOpenHelp(false);
            }}
          >
            Raise Ticket
          </ListItem>

          {partner_level !== "L3" && (
            <ListItem
              button
              onClick={() => {
                window.open(
                  "https://help.finmo.net/support/solutions",
                  "_blank"
                );
                setOpenHelp(false);
              }}
            >
              <ListItemText primary={t("FAQs")} />
            </ListItem>
          )}
        </List>
      </Popover>
    </Box>
  );
}

export default HeaderUserbox;
