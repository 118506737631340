import InfoIcon from "@mui/icons-material/Info";
import { Alert, AlertTitle } from "@mui/material";
import { useTranslation } from "react-i18next";

const getAlertMsgByUserStatus = (user) => {
  let msg = "Access Restricted. Please ";
  if (!user.is_email_verified) msg += "verify your Email.";
  else if (!user.is_mobile_verified) msg += "verify your Mobile number.";
  else if (user.partner_level === "L3") msg += "activate your account.";
  else msg += "activate your Finmo account.";

  msg += " Meanwhile, you may continue to test on sandbox.";

  return msg;
};
const RestrictedAlertBox = ({ user }) => {
  const { t } = useTranslation();
  return (
    <Alert
      variant="filled"
      severity="info"
      sx={{ p: 0 }}
      icon={
        <InfoIcon
          fontSize="small"
          sx={{
            position: "relative",
            left: "4px",
            marginLeft: "4px",
          }}
        />
      }
    >
      <AlertTitle
        sx={{
          fontSize: "12px",
          position: "relative",
          marginRight: "10px",
          top: "3px",
        }}
      >
        {t(getAlertMsgByUserStatus(user))}
      </AlertTitle>
    </Alert>
  );
};

export default RestrictedAlertBox;
