import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import PaymentTwoToneIcon from "@mui/icons-material/PaymentTwoTone";
import PaymentsTwoToneIcon from "@mui/icons-material/PaymentsTwoTone";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ShareIcon from "@mui/icons-material/Share";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import MonetizationOnTwoToneIcon from "@mui/icons-material/MonetizationOnTwoTone";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import ReceiptTwoToneIcon from "@mui/icons-material/ReceiptTwoTone";
import PublicIcon from "@mui/icons-material/Public";
import { AssessmentOutlined, SavingsTwoTone } from "@mui/icons-material";
import AccountTreeTwoToneIcon from "@mui/icons-material/AccountTreeTwoTone";
import AutorenewIcon from "@mui/icons-material/Autorenew"; // Rotate icon

export const RenderSidebarMenuItemList = (user, is_local_rails_enabled) => {
  const { partner_id, partner_level } = user;

  let sidebar_menu_item_list = menuItems;

  if (!!partner_id && partner_level.toUpperCase() !== "L1") {
    if (is_local_rails_enabled) {
      sidebar_menu_item_list = localRailsPartnerMenuItems;
    } else {
      sidebar_menu_item_list = partnerMenuItems;
    }
  }
  return sidebar_menu_item_list;
};

export const partnerMenuItems = [
  {
    heading: "Dashboard",
    items: [
      {
        name: "GCA",
        mode: "all",
        route_id: "read_only",
        link: "/preview/dashboards/global-currency-account/dashboard",
        icon: PublicIcon,
      },
      {
        name: "Conversions",
        mode: "all",
        route_id: "read_only",
        link: "/preview/dashboards/global-currency-conversion",
        icon: CurrencyExchangeIcon,
      },
      {
        name: "Organization Users",
        mode: "all",
        icon: AccountCircleTwoToneIcon,
        items: [
          {
            name: "All Users",
            route_id: "read_only",
            link: "/preview/dashboards/users",
          },
          {
            name: "Add New User",
            link: "/preview/dashboards/add-user",
          },
        ],
      },
      {
        name: "Invest",
        mode: "all",
        icon: SavingsTwoTone,
        link: "/preview/dashboards/invest",
      },
      {
        name: "Payin",
        mode: "all",
        icon: PaymentTwoToneIcon,
        items: [
          {
            name: "All Payins",
            route_id: "read_only",
            link: "/preview/dashboards/payments",
          },
        ],
      },
      {
        name: "Payout",
        mode: "all",
        icon: PaymentsTwoToneIcon,
        items: [
          {
            name: "All Payout",
            route_id: "read_only",
            link: "/preview/dashboards/all-payouts",
          },
          {
            name: "Create international payout",
            link: "/preview/dashboards/add-payout/gca",
          },
          {
            name: "Sender",
            items: [
              {
                name: "All Sender",
                route_id: "read_only",
                link: "/preview/dashboards/payout-sender",
              },
              {
                name: "Create Sender",
                link: "/preview/dashboards/add-payout-sender",
              },
            ],
          },
          {
            name: "Beneficiary",
            items: [
              {
                name: "All Beneficiary",
                route_id: "read_only",
                link: "/preview/dashboards/payout-beneficiary",
              },
              {
                name: "Create Beneficiary",
                link: "/preview/dashboards/add-payout-beneficiary",
              },
            ],
          },
        ],
      },
      {
        name: "Customer",
        mode: "all",
        icon: AssignmentTurnedInIcon,
        items: [
          {
            name: "All Customers",
            route_id: "read_only",
            link: "/preview/dashboards/customers",
          },
          {
            name: "Add New Customer",
            link: "/preview/dashboards/new-customer",
          },
        ],
      },
      {
        name: "Transaction Ledger",
        mode: "all",
        icon: ReceiptTwoToneIcon,
        items: [
          {
            name: "All Transaction",
            route_id: "read_only",
            link: "/preview/dashboards/transactions",
          },
        ],
      },

      {
        name: "Reporting",
        mode: "all",
        icon: AssessmentOutlined,
        items: [
          {
            name: "Reports",
            route_id: "read_only",
            link: "/preview/dashboards/report",
          },
        ],
      },
      {
        name: "Settings",
        mode: "all",
        route_id: "read_only",
        icon: SettingsTwoToneIcon,
        items: [
          {
            name: "IP Whitelisting",
            link: "/preview/dashboards/setting/security/iplisting",
          },
        ],
      },
    ],
  },
];

export const localRailsPartnerMenuItems = [
  {
    heading: "Dashboard",
    items: [
      {
        name: "Home",
        mode: "all",
        route_id: "read_only",
        icon: HomeTwoToneIcon,
        link: "/preview/dashboards/analytics",
      },

      {
        name: "My Organization",
        mode: "all",
        icon: AccountCircleTwoToneIcon,
        items: [
          {
            name: "Account Details",
            route_id: "read_only",
            link: "/preview/dashboards/account-detail",
          },
          {
            name: "Business Details",
            route_id: "read_only",
            link: "/preview/dashboards/business-detail",
          },
          {
            name: "Pricing",
            route_id: "read_only",
            link: "/preview/dashboards/pricing",
          },
          {
            name: "Payment Method Config",
            route_id: "read_only",
            link: "/preview/dashboards/payment-method-setting",
          },
          // {
          //   name: "MSA",
          //   route_id: "read_only",
          //   link: "/preview/dashboards/msa",
          // },
          {
            name: "Users",
            mode: "all",
            items: [
              {
                name: "All Users",
                route_id: "read_only",
                link: "/preview/dashboards/users",
              },
              {
                name: "Add New User",
                link: "/preview/dashboards/add-user",
              },
            ],
          },
        ],
      },

      {
        name: "Payin",
        mode: "all",
        icon: PaymentTwoToneIcon,
        items: [
          // {
          //   name: "Overview",
          //   route_id: "read_only",
          //   link: "/preview/dashboards/payments/overview",
          // },
          {
            name: "All Payins",
            route_id: "read_only",
            link: "/preview/dashboards/payments",
          },
          {
            name: "All Refunds",
            route_id: "read_only",
            link: "/preview/dashboards/refunds",
          },
          {
            name: "Virtual Account",
            mode: "all",
            items: [
              {
                name: "All Virtual Accounts",
                route_id: "read_only",
                link: "/preview/dashboards/virtual-accounts",
              },
              {
                name: "Create Virtual Account",
                link: "/preview/dashboards/create-virtual-account",
              },
            ],
          },
          {
            name: "Sender",
            mode: "all",
            items: [
              {
                name: "All Sender",
                route_id: "read_only",
                link: "/preview/dashboards/payin-sender",
              },
              {
                name: "Create Sender",
                link: "/preview/dashboards/payin-sender/create-sender",
              },
            ],
          },
          {
            name: "Checkout",
            mode: "all",
            items: [
              {
                name: "All Checkout Sessions",
                route_id: "read_only",
                link: "/preview/dashboards/sessions",
              },
              {
                name: "Create Payment Link",
                link: "/preview/dashboards/checkout",
              },
            ],
          },
          {
            name: "Disputed Transaction",
            route_id: "read_only",
            link: "/preview/dashboards/dispute",
          },
          {
            name: "AR Invoices",
            route_id: "read_only",
            link: "/preview/dashboards/ar-invoices",
          },
        ],
      },
      {
        name: "Payout",
        mode: "all",
        icon: PaymentsTwoToneIcon,
        items: [
          {
            name: "All Payout",
            route_id: "read_only",
            link: "/preview/dashboards/all-payouts",
          },
          {
            name: "Create local payout",
            link: "/preview/dashboards/add-payout/local",
          },
          {
            name: "Create international payout",
            link: "/preview/dashboards/add-payout/gca",
          },
          {
            name: "Bulk Payout",
            mode: "all",
            items: [
              {
                name: "Create bulk payout",
                link: "/preview/dashboards/bulk-payout",
              },
              {
                name: "All bulk payouts",
                link: "/preview/dashboards/all-bulk-payouts",
                route_id: "read_only",
              },
            ],
          },
          {
            name: "Sender",
            items: [
              {
                name: "All Sender",
                route_id: "read_only",
                link: "/preview/dashboards/payout-sender",
              },
              {
                name: "Create Sender",
                link: "/preview/dashboards/add-payout-sender",
              },
            ],
          },
          {
            name: "Beneficiary",
            items: [
              {
                name: "All Beneficiary",
                route_id: "read_only",
                link: "/preview/dashboards/payout-beneficiary",
              },
              {
                name: "Create Beneficiary",
                link: "/preview/dashboards/add-payout-beneficiary",
              },
            ],
          },
        ],
      },
      {
        name: "GCA",
        mode: "all",
        route_id: "read_only",
        icon: PublicIcon,
        items: [
          {
            name: "GCA",
            route_id: "read_only",
            link: "/preview/dashboards/global-currency-account/dashboard",
          },
          {
            name: "GCA Conversions",
            route_id: "read_only",
            link: "/preview/dashboards/global-currency-conversion",
          },
        ],
      },
      {
        name: "Stable Coin",
        mode: "all",
        route_id: "read_only",
        icon: CurrencyExchangeIcon,
        items: [
          {
            name: "Stable Coin Conversion",
            route_id: "read_only",
            link: "/preview/dashboards/stablecoin-conversion/dashboard",
          },
          {
            name: "Stable Coin Transactions",
            route_id: "read_only",
            link: "/preview/dashboards/stablecoin-transactions",
          },
        ],
      },
      {
        name: "Treasury Dashboard",
        mode: "all",
        icon: AccountBalanceIcon,
        link: "/preview/dashboards/tos?section=currency",
      },
      {
        name: "Invest",
        mode: "all",
        icon: SavingsTwoTone,
        link: "/preview/dashboards/invest",
      },
      {
        name: "Foreign Exchange",
        mode: "all",
        icon: CurrencyExchangeIcon,
        link: "/preview/dashboards/tos-fx-trade-form",
      },

      {
        name: "Customer",
        mode: "all",
        icon: AssignmentTurnedInIcon,
        items: [
          {
            name: "All Customers",
            route_id: "read_only",
            link: "/preview/dashboards/customers",
          },
          {
            name: "Add New Customer",
            link: "/preview/dashboards/new-customer",
          },
          {
            name: "Wallet",
            mode: "all",
            items: [
              {
                name: "All Wallets",
                route_id: "read_only",
                link: "/preview/dashboards/wallets",
              },
              {
                name: "Create Wallet",
                link: "/preview/dashboards/create-wallet",
              },
              {
                name: "Wallet Transactions",
                route_id: "read_only",
                link: "/preview/dashboards/wallets/transaction",
              },
            ],
          },
        ],
      },
      {
        name: "Wallet Fund Transfer",
        mode: "all",
        icon: ShareIcon,
        items: [
          {
            name: "All Wallet Fund Transfer",
            route_id: "read_only",
            link: "/preview/dashboards/wallet-fund-transfer",
          },
          {
            name: "Initiate Wallet Fund Transfer",
            link: "/preview/dashboards/create-wallet-fund-transfer",
          },
        ],
      },
      {
        name: "Transaction Ledger",
        mode: "all",
        icon: ReceiptTwoToneIcon,
        items: [
          {
            name: "All Transaction",
            route_id: "read_only",
            link: "/preview/dashboards/transactions",
          },
          {
            name: "Org Level ",
            route_id: "read_only",
            link: "/preview/dashboards/transaction/orglevel",
          },
          {
            name: "Customer Level",
            route_id: "read_only",
            link: "/preview/dashboards/transaction/customerlevel",
          },
        ],
      },

      {
        name: "Billing",
        mode: "all",
        icon: MonetizationOnTwoToneIcon,
        items: [
          {
            name: "Settlements",
            route_id: "read_only",
            link: "/preview/dashboards/settlements",
          },

          {
            name: "Invoices",
            route_id: "read_only",
            link: "/preview/dashboards/invoices",
          },
        ],
      },

      {
        name: "Reporting",
        mode: "all",
        icon: AssessmentOutlined,
        items: [
          {
            name: "Reports",
            route_id: "read_only",
            link: "/preview/dashboards/report",
          },
          {
            name: "Adjustments",
            route_id: "read_only",
            link: "/preview/dashboards/adjustments",
          },
          {
            name: "Fees Adjustments",
            route_id: "read_only",
            link: "/preview/dashboards/fees-adjustments",
          },
          {
            name: "Audit Trail",
            route_id: "read_only",
            link: "/preview/dashboards/audit-trail",
          },
        ],
      },
      {
        name: "Developer",
        mode: "all",
        icon: BuildOutlinedIcon,
        items: [
          // {
          //   name: "API Keys",
          //   route_id: "read_only",
          //   link: "/preview/dashboards/setting/developer/api",
          // },
          {
            name: "Authentication",
            route_id: "read_only",
            link: "/preview/dashboards/setting/developer/authentication",
          },
          {
            name: "Plugin",
            route_id: "read_only",
            link: "/preview/dashboards/setting/developer/plugin",
          },
          {
            name: "Webhooks",
            route_id: "read_only",
            link: "/preview/dashboards/setting/developer/webhook-url",
          },
        ],
      },
      // {
      //   name: 'Contract',
      //   mode: 'all',
      //   icon: AssignmentTwoToneIcon,
      //   link: '/preview/dashboards/contract'
      // },

      // {
      //   name: "API",
      //   mode: "all",
      //   icon: ContactSupportTwoToneIcon,
      //   link: "/preview/dashboards/finmo-api",
      // },
      {
        name: "Settings",
        mode: "all",
        route_id: "read_only",
        icon: SettingsTwoToneIcon,
        link: "/preview/dashboards/setting",
      },
      // {
      //   name: "Help",
      //   mode: "all",
      //   icon: ContactSupportTwoToneIcon,
      //   link: "/preview/dashboards/helpdesk",
      // },
    ],
  },
];

export const menuItems = [
  {
    heading: "Dashboard",
    items: [
      {
        name: "Home",
        mode: "all",
        route_id: "read_only",
        icon: HomeTwoToneIcon,
        link: "/preview/dashboards/analytics",
      },
      {
        name: "View Hierarchy",
        mode: "all",
        route_id: "read_only",
        icon: AccountTreeTwoToneIcon,
        link: "/preview/dashboards/view-hierarchy",
      },
      {
        name: "Switch Organization",
        mode: "all",
        route_id: "read_only",
        icon: AutorenewIcon,
        link: "/preview/dashboards/switch-organization",
      },
      {
        name: "My Organization",
        mode: "all",
        icon: AccountCircleTwoToneIcon,
        items: [
          {
            name: "Account Details",
            route_id: "read_only",
            link: "/preview/dashboards/account-detail",
          },
          {
            name: "Business Details",
            route_id: "read_only",
            link: "/preview/dashboards/business-detail",
          },
          {
            name: "Pricing",
            route_id: "read_only",
            link: "/preview/dashboards/pricing",
          },
          {
            name: "Payment Method Config",
            route_id: "read_only",
            link: "/preview/dashboards/payment-method-setting",
          },
          {
            name: "MSA",
            route_id: "read_only",
            link: "/preview/dashboards/msa",
          },
          {
            name: "Users",
            mode: "all",
            items: [
              {
                name: "All Users",
                route_id: "read_only",
                link: "/preview/dashboards/users",
              },
              {
                name: "Add New User",
                link: "/preview/dashboards/add-user",
              },
            ],
          },
        ],
      },

      {
        name: "Payin",
        mode: "all",
        icon: PaymentTwoToneIcon,
        items: [
          // {
          //   name: "Overview",
          //   route_id: "read_only",
          //   link: "/preview/dashboards/payments/overview",
          // },
          {
            name: "All Payins",
            route_id: "read_only",
            link: "/preview/dashboards/payments",
          },
          {
            name: "All Refunds",
            route_id: "read_only",
            link: "/preview/dashboards/refunds",
          },
          {
            name: "Virtual Account",
            mode: "all",
            items: [
              {
                name: "All Virtual Accounts",
                route_id: "read_only",
                link: "/preview/dashboards/virtual-accounts",
              },
              {
                name: "Create Virtual Account",
                link: "/preview/dashboards/create-virtual-account",
              },
            ],
          },
          {
            name: "Sender",
            mode: "all",
            items: [
              {
                name: "All Sender",
                route_id: "read_only",
                link: "/preview/dashboards/payin-sender",
              },
              {
                name: "Create Sender",
                link: "/preview/dashboards/payin-sender/create-sender",
              },
            ],
          },
          {
            name: "Checkout",
            mode: "all",
            items: [
              {
                name: "All Checkout Sessions",
                route_id: "read_only",
                link: "/preview/dashboards/sessions",
              },
              {
                name: "Create Payment Link",
                link: "/preview/dashboards/checkout",
              },
            ],
          },
          {
            name: "Disputed Transaction",
            route_id: "read_only",
            link: "/preview/dashboards/dispute",
          },
          {
            name: "AR Invoices",
            route_id: "read_only",
            link: "/preview/dashboards/ar-invoices",
          },
        ],
      },
      {
        name: "Payout",
        mode: "all",
        icon: PaymentsTwoToneIcon,
        items: [
          {
            name: "All Payout",
            route_id: "read_only",
            link: "/preview/dashboards/all-payouts",
          },
          {
            name: "Create local payout",
            link: "/preview/dashboards/add-payout/local",
          },
          {
            name: "Create international payout",
            link: "/preview/dashboards/add-payout/gca",
          },
          {
            name: "Bulk Payout",
            mode: "all",
            items: [
              {
                name: "Create bulk payout",
                link: "/preview/dashboards/bulk-payout",
              },
              {
                name: "All bulk payouts",
                link: "/preview/dashboards/all-bulk-payouts",
                route_id: "read_only",
              },
            ],
          },
          {
            name: "Sender",
            items: [
              {
                name: "All Sender",
                route_id: "read_only",
                link: "/preview/dashboards/payout-sender",
              },
              {
                name: "Create Sender",
                link: "/preview/dashboards/add-payout-sender",
              },
            ],
          },
          {
            name: "Beneficiary",
            items: [
              {
                name: "All Beneficiary",
                route_id: "read_only",
                link: "/preview/dashboards/payout-beneficiary",
              },
              {
                name: "Create Beneficiary",
                link: "/preview/dashboards/add-payout-beneficiary",
              },
            ],
          },
        ],
      },
      {
        name: "GCA",
        mode: "all",
        route_id: "read_only",
        icon: PublicIcon,
        items: [
          {
            name: "GCA",
            route_id: "read_only",
            link: "/preview/dashboards/global-currency-account/dashboard",
          },
          {
            name: "GCA Conversions",
            route_id: "read_only",
            link: "/preview/dashboards/global-currency-conversion",
          },
        ],
      },
      {
        name: "Stable Coin",
        mode: "all",
        route_id: "read_only",
        icon: CurrencyExchangeIcon,
        items: [
          {
            name: "Stable Coin Conversion",
            route_id: "read_only",
            link: "/preview/dashboards/stablecoin-conversion/dashboard",
          },
          {
            name: "Stable Coin Transactions",
            route_id: "read_only",
            link: "/preview/dashboards/stablecoin-transactions",
          },
        ],
      },
      {
        name: "Treasury Dashboard",
        mode: "all",
        icon: AccountBalanceIcon,
        link: "/preview/dashboards/tos?section=currency",
      },
      {
        name: "Invest",
        mode: "all",
        icon: SavingsTwoTone,
        link: "/preview/dashboards/invest",
      },
      {
        name: "Foreign Exchange",
        mode: "all",
        icon: CurrencyExchangeIcon,
        link: "/preview/dashboards/tos-fx-trade-form",
      },

      {
        name: "Customer",
        mode: "all",
        icon: AssignmentTurnedInIcon,
        items: [
          {
            name: "All Customers",
            route_id: "read_only",
            link: "/preview/dashboards/customers",
          },
          {
            name: "Add New Customer",
            link: "/preview/dashboards/new-customer",
          },
          {
            name: "Wallet",
            mode: "all",
            items: [
              {
                name: "All Wallets",
                route_id: "read_only",
                link: "/preview/dashboards/wallets",
              },
              {
                name: "Create Wallet",
                link: "/preview/dashboards/create-wallet",
              },
              {
                name: "Wallet Transactions",
                route_id: "read_only",
                link: "/preview/dashboards/wallets/transaction",
              },
            ],
          },
        ],
      },
      {
        name: "Wallet Fund Transfer",
        mode: "all",
        icon: ShareIcon,
        items: [
          {
            name: "All Wallet Fund Transfer",
            route_id: "read_only",
            link: "/preview/dashboards/wallet-fund-transfer",
          },
          {
            name: "Initiate Wallet Fund Transfer",
            link: "/preview/dashboards/create-wallet-fund-transfer",
          },
        ],
      },
      {
        name: "Transaction Ledger",
        mode: "all",
        icon: ReceiptTwoToneIcon,
        items: [
          {
            name: "All Transaction",
            route_id: "read_only",
            link: "/preview/dashboards/transactions",
          },
          {
            name: "Org Level ",
            route_id: "read_only",
            link: "/preview/dashboards/transaction/orglevel",
          },
          {
            name: "Customer Level",
            route_id: "read_only",
            link: "/preview/dashboards/transaction/customerlevel",
          },
        ],
      },

      {
        name: "Billing",
        mode: "all",
        icon: MonetizationOnTwoToneIcon,
        items: [
          {
            name: "Settlements",
            route_id: "read_only",
            link: "/preview/dashboards/settlements",
          },

          {
            name: "Invoices",
            route_id: "read_only",
            link: "/preview/dashboards/invoices",
          },
        ],
      },

      {
        name: "Reporting",
        mode: "all",
        icon: AssessmentOutlined,
        items: [
          {
            name: "Reports",
            route_id: "read_only",
            link: "/preview/dashboards/report",
          },
          {
            name: "Adjustments",
            route_id: "read_only",
            link: "/preview/dashboards/adjustments",
          },
          {
            name: "Fees Adjustments",
            route_id: "read_only",
            link: "/preview/dashboards/fees-adjustments",
          },
          {
            name: "Audit Trail",
            route_id: "read_only",
            link: "/preview/dashboards/audit-trail",
          },
        ],
      },
      {
        name: "Developer",
        mode: "all",
        icon: BuildOutlinedIcon,
        items: [
          {
            name: "API Keys",
            route_id: "read_only",
            link: "/preview/dashboards/setting/developer/api",
          },
          {
            name: "Authentication",
            route_id: "read_only",
            link: "/preview/dashboards/setting/developer/authentication",
          },
          {
            name: "Plugin",
            route_id: "read_only",
            link: "/preview/dashboards/setting/developer/plugin",
          },
          {
            name: "Webhooks",
            route_id: "read_only",
            link: "/preview/dashboards/setting/developer/webhook-url",
          },
        ],
      },
      // {
      //   name: 'Contract',
      //   mode: 'all',
      //   icon: AssignmentTwoToneIcon,
      //   link: '/preview/dashboards/contract'
      // },

      // {
      //   name: "API",
      //   mode: "all",
      //   icon: ContactSupportTwoToneIcon,
      //   link: "/preview/dashboards/finmo-api",
      // },
      {
        name: "Settings",
        mode: "all",
        route_id: "read_only",
        icon: SettingsTwoToneIcon,
        link: "/preview/dashboards/setting",
      },

      // {
      //   name: "Help",
      //   mode: "all",
      //   icon: ContactSupportTwoToneIcon,
      //   link: "/preview/dashboards/helpdesk",
      // },
    ],
  },
];
